<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-menu
                    ref="menu"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :clearable="true"
                        v-model="startDateFormatted"
                        :label="$t('evoucher.issuance.fromDate')"
                        persistent-hint
                        @blur="date = parseDate(startDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      :min="minStartDate"
                      no-title
                      @input="menuStartDate = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-menu
                    ref="menu"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :clearable="true"
                        v-model="endDateFormatted"
                        :label="$t('evoucher.issuance.toDate')"
                        persistent-hint
                        @blur="date = parseDate(endDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :min="minEndDate"
                      no-title
                      @input="menuEndDate = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.voucherName"
                    :label="$t('voucherUsed.voucherName')"
                    @keyup.enter="getVoucherUsages"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.usedSite"
                    :label="$t('report.supplier.usedSite')"
                    @keyup.enter="getVoucherUsages"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.issuanceCode"
                    :label="$t('evoucher.issuance.workpiece')"
                    @keyup.enter="getVoucherUsages"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getVoucherUsages()"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('report.supplier.voucherConfirmationTitle')"
          color="green"
          flat
          full-width
        >
          <template>
            <!-- Create check voucher -->
            <v-btn
              v-if="!isEntityDisabled()"
              color="success"
              dark
              style="float: right;"
              @click="onExportExcel()"
            >
              <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
              <span style="text-transform: none;">{{
                $t("voucherReport.exportExcel")
              }}</span>
            </v-btn>
          </template>
          <v-data-table
            :headers="voucherInfoHeaders"
            :items="voucherInfoList"
            :no-data-text="$t('common.noDataAvailable')"
            v-model="selected"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template v-slot:items="props">
              <tr
                :class="props.item.isLoading ? 'bg-1' : ''"
                style="position: relative;"
              >
                <td
                  v-for="(header, index) in voucherInfoHeaders"
                  :key="header.value + index"
                >
                  <div v-if="header.value == 'price'">
                    <div v-if="props.item['issuanceType'] === VoucherType.COUPON">
                      {{ props.item[header.value] }}%
                    </div>
                    <div>
                      {{ formatMoney(props.item[header.value]) }}
                    </div>
                  </div>
                  <div v-else-if="header.value == 'totalFee'">
                    <div v-if="props.item['issuanceType'] === VoucherType.COUPON">
                      -
                    </div>
                    <div v-else>
                      {{
                        props.item[header.value] > 0
                          ? formatMoney(props.item[header.value])
                          : formatMoney(
                            props.item["numberVoucherCustomerUsed"] *
                              props.item["price"]
                          )
                      }}
                    </div>
                  </div>
                  <div v-else-if="header.value == 'numberVoucherCustomerUsed'">
                    <div>
                      {{ props.item[header.value] }}
                    </div>
                  </div>
                  <span v-else>{{ props.item[header.value] }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import moment from 'moment'
import stringUtils from 'utils/stringUtils'
import EntityType from 'enum/entityType'
import VoucherType from 'enum/voucherType'
import VoucherReconciliationType from 'enum/voucherReconciliationType'
import XLSX from 'xlsx'
export default {
  data: () => ({
    voucherInfoHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'report.supplier.usedSite', value: 'usedSite' },
      { text: 'voucherUsed.voucherName', value: 'voucherName' },
      { text: 'evoucher.issuance.workpiece', value: 'issuanceCode' },
      { text: 'voucherUsed.price', value: 'price' },
      {
        text: 'report.supplier.numberVoucherCustomerUsed',
        value: 'numberVoucherCustomerUsed'
      },
      { text: 'report.supplier.totalFee', value: 'totalFee' }
    ],
    voucherInfoList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    selected: [],
    search: {
      voucherName: null,
      orderCode: null,
      issuanceCode: null,
      usedSite: null
    },
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false,
    minStartDate: null,
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false,
    minEndDate: null,
    voucherReconciliationType: VoucherReconciliationType.NOT_SUBMIT_YET,
    voucherReconciliationTypeList: stringUtils.VoucherReconciliationTypeList,
    supplierId: null,
    supplierList: [],
    exportExcelData: {
      headerList: [
        'common.nonumber',
        'report.supplier.usedSite',
        'voucherUsed.voucherName',
        'evoucher.issuance.workpiece',
        'voucherUsed.price',
        'report.supplier.numberVoucherCustomerUsed',
        'report.supplier.totalFee'
      ],
      dataList: []
    },
    VoucherType: VoucherType
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_LOCAL_USED_VOUCHERS_LIST_DATA'
    ])
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD')
        .add(1, 'days')
        .format('YYYY-MM-DD')
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    GET_LOCAL_USED_VOUCHERS_LIST_DATA () {
      let res = this.GET_LOCAL_USED_VOUCHERS_LIST_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let voucherData = res.results
      this.voucherInfoList = []
      for (let i = 0, size = voucherData.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          issuanceCode: functionUtils.concatSuffixPrefix(voucherData[i].issuance),
          issuanceType: voucherData[i].workpiece.type,
          voucherName: voucherData[i].workpiece.name,
          usedSite: voucherData[i].entity_info.name,
          numberVoucherCustomerUsed: voucherData[i].number_voucher_used,
          totalFee: voucherData[i].evoucher_usages_total_fee,
          price: voucherData[i].workpiece.value
        }
        this.voucherInfoList.push(obj)
      }
    }
  },
  created () {
    this.getVoucherUsages()
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    /**
     * Check is site
     */
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVoucherUsages()
    },
    /**
     * Get vouchers usage
     */
    getVoucherUsages: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          voucherName: this.search.voucherName,
          reconciliationType: this.voucherReconciliationType,
          startDate: this.startDateFormatted,
          endDate: this.endDateFormatted,
          issuanceCode: this.search.issuanceCode,
          usedSite: this.search.usedSite
        }
      }
      this.isLoading = true
      this.GET_LOCAL_USED_VOUCHERS_LIST(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getReconciliationStatusText: function (value) {
      return functionUtils.getReconciliationStatusText(value)
    },
    onExportExcel: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        params: {
          voucherName: this.search.voucherName,
          reconciliationType: this.voucherReconciliationType,
          startDate: this.startDateFormatted,
          endDate: this.endDateFormatted,
          issuanceCode: this.search.issuanceCode,
          usedSite: this.search.usedSite
        }
      }
      this.isLoading = true
      this.GET_LOCAL_USED_VOUCHERS_LIST(filter)
        .then(
          function (res) {
            this.isLoading = false
            let data = res.data
            let dataList = this.getDataExcelJson(
              this.exportExcelData.headerList,
              data
            )
            this.createExcelFile(dataList)
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    getDataExcelJson: function (headerList, dataList) {
      let resultList = []

      //
      // let headerListLength = headerList.length
      let nonumberHeader = headerList[0]
      let usedSiteHeader = headerList[1]
      let voucherNameHeader = headerList[2]
      let voucherIssuanceHeader = headerList[3]
      let priceHeader = headerList[4]
      let numberVoucherCustomerUseHeader = headerList[5]
      let totalFeeHeader = headerList[6]

      dataList.forEach((data, index) => {
        let item = {
          [this.$t(nonumberHeader)]: index + 1,
          [this.$t(usedSiteHeader)]: data.entity_info.name,
          [this.$t(voucherNameHeader)]: data.workpiece.name,
          [this.$t(voucherIssuanceHeader)]: functionUtils.concatSuffixPrefix(data.issuance),
          [this.$t(priceHeader)]: '',
          [this.$t(numberVoucherCustomerUseHeader)]: data.number_voucher_used,
          [this.$t(totalFeeHeader)]: data.evoucher_usages_total_fee
        }
        resultList.push(item)
      })
      return resultList
    },
    createExcelFile: function (jsonData) {
      let dataList = []

      dataList = jsonData
      // export json to Worksheet of Excel
      // only array possible
      var assetFile = XLSX.utils.json_to_sheet(dataList)
      // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, assetFile, 'ReportVoucherUsedSheet') // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

      // export Excel file
      XLSX.writeFile(wb, 'ReportVoucherUsed.xlsx') // name of the file is 'book.xlsx'
    },
    ...mapActions([
      'GET_LOCAL_USED_VOUCHERS_LIST'
    ])
  }
}
</script>

<style></style>
